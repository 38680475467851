export enum StreamEvent {
  /**
   * The connection has been lost and we are attenpting to reconnect.
   */
  RECONNECTING,
  /**
   * We just connected.
   */
  CONNECTED,
}
